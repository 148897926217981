const top40Rewards: {[key:number]:number} = {
    1:  5000,
        2:  3000,
        3:  2000,
        4:  1000,
        5:  900,
        6:  800,
        7:  700,
        8:  600,
        9:  500,
        10: 400,

        11: 380,
        12: 360,
        13: 340,
        14: 320,
        15: 300,
        16: 280,
        17: 260,
        18: 240,
        19: 220,
        20: 200,

        21: 190,
        22: 180,
        23: 170,
        24: 160,
        25: 150,
        26: 140,
        27: 130,
        28: 120,
        29: 110,
        30: 100,

        31: 95,
        32: 90,
        33: 85,
        34: 80,
        35: 75,
        36: 70,
        37: 65,
        38: 60,
        39: 55,
        40: 50,
};

export const applyReward = (pos: number): number => {
    if (pos <= 40) return top40Rewards[pos];
    if(pos <= 50) return 50;
    if(pos <= 100) return 40;
    if(pos <= 200) return 30;
    if(pos <= 300) return 25;
    if(pos <= 400) return 15;
    if(pos <= 500) return 5;

    return 0;
}