// src/pages/FriendsPage.tsx
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { RootState } from '../../redux/store';
import FrensList from '../../components/FrensList';
import s from "./FriendsPage.module.css"
import SimpleButton from '../../components/common/SimpleButton';
import { telegramShare } from "../../services/telegram";
import PageContainer from '../../components/common/PageContainer';
import ImageComponent from '../../components/common/ImageComponent';
import ImageComponentBG from '../../components/common/ImageComponentBG';
import {showToast} from "../../redux/slices/gameSlice";

const FriendsPage: React.FC = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.game.user);
    // fixme: change to global db config
    const rewardPerFriend = 350;
    const { link, handleGenerateLink } = telegramShare(user);

    const { friends } = useSelector((state: RootState) => state.game);

    const handleButtonClick = () => {
        navigator.clipboard.writeText(link)
            .then(() => {
                dispatch(showToast('Copied to clipboard'));
            })
            .catch(err => {
            });
    };

    return (
        <>
            <PageContainer>
                <div className={s.baseBox}>

                    <ImageComponentBG className={`${s.base} ${s.left} ${s.animateLeft}`} imageUrl={`/images/frens/frens_invited_base.webp`}>
                        <div className={s.textBox}>
                            <p className={`${s.text} ${s.frensRotate}`}>frens invited</p>
                            <p className={s.lowerText}>{Object.keys(friends).length}</p>
                        </div>
                        <ImageComponent className={s.baseImg} src={`/images/tabbar/ic_tabbar_frens_act.webp`} alt="frens" />
                    </ImageComponentBG>

                    <ImageComponentBG className={`${s.base} ${s.right} ${s.animateRight}`} imageUrl={`/images/frens/frens_earned_base.webp`}>
                        <ImageComponent className={s.baseImg} src={`/images/header/coin.webp`} alt="coin" />
                        <div className={s.textBox}>
                            <p className={`${s.text} ${s.earnedRotate}`}>baa earned</p>
                            <p className={s.lowerText}>{friends.filter((el) => el.is_active).length * rewardPerFriend}</p>
                        </div>
                    </ImageComponentBG>
                </div>
                {/* ================ */}
                <ImageComponentBG className={s.titleOnBase} imageUrl={"/images/frens/frens_list_base.webp"}>

                    <p className={s.text}>frens list</p>
                </ImageComponentBG>


                {friends.length > 0
                    ? <FrensList arrayItems={friends} />
                    : <div className={s.noFriendsWrapper}>
                        <img className={s.noFriendsImg} src={"/images/frens/no_friends.webp"} alt="no_friends" />
                    </div>
                }

                {/* ==================== */}

                <ImageComponentBG className={s.referBase} imageUrl={"/images/frens/refer_base.webp"}>
                    <p className={s.textReferalTop}>Refer a fren and earn</p>
                    <div className={s.refCoinBox}>
                        <ImageComponent className={s.refCoinImg} src={`/images/header/coin.webp`} alt="coin" />
                        <p className={s.textReferalEarn}>{rewardPerFriend} BAA</p>
                    </div>
                    <p className={s.textReferal}>for you and your friends</p>
                    <div className={s.refBtnsBox}>
                        <SimpleButton className={`${s.referalBtns} ${s.inviteFriends}`} onClick={handleGenerateLink} type="button" imageUrl={`/images/frens/invite_friends_btn.webp`}>
                            <p className={s.textReferalBtn}>Invite Friends</p>
                        </SimpleButton>
                        {/* <SimpleButton imageUrl={`/images/frens/copy_btn.webp`} width={13.33} onClick={() => navigator.clipboard.writeText(link)} /> */}
                        <SimpleButton imageUrl={`/images/frens/copy_btn.webp`} width={13.33} onClick={handleButtonClick} />
                    </div>
                </ImageComponentBG>

            </PageContainer>
        </>
    );
};

export default FriendsPage;
