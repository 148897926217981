import s from './ModalQRContent.module.css'
import QRCode from "react-qr-code";

const ModalQRContent: React.FC<{}> = () => {
    const botUrl = process.env.REACT_APP_BOT_URL;

    return (
        <div className={s.container}>
            <div className={s.qrBaseBox}>
                <img className={s.sheepImg} src={`/images/modalQRContent/sheep.webp`} alt="sheep" />
                <QRCode
                    size={200}
                    value={`${botUrl}?start`}
                />
                {/*<img className={s.qrCodeImg} src={`/images/modalQRContent/qr_code.webp`} alt="qrCode" />*/}
                <p className={s.baaText}>@BaaTapBot</p>
            </div>
            <footer className={s.footer}>
                <img className={s.arrowImg} src={`/images/modalQRContent/arrow.webp`} alt="arrow" />
                <p className={s.footerText}>desktop is boring.</p>
                <p className={s.footerText}>play on your mobile.</p>
            </footer>
        </div>
    )
}

export default ModalQRContent