// src/redux/slices/index.ts
import { combineReducers } from '@reduxjs/toolkit';

// Import your individual reducers here
import gameReducer from './gameSlice';
import boostReducer from './boostSlice'; // Import the earn slice
import tasksReducer from './tasksSlice'; // Import the earn slice
import leaderboardReducer from './leaderboardSlice'; // Import the airdrop slice
import promoReducer from './promoSlice'; // Import the airdrop slice

const rootReducer = combineReducers({
    game: gameReducer,
    boost: boostReducer, // Add earn reducer
    tasks: tasksReducer, // Add earn reducer
    leaderboard: leaderboardReducer, // Add airdrop reducer
    promo: promoReducer, // Add airdrop reducer
});

export default rootReducer;
