import s from "./FrensList.module.css"
import FrensListItem from "./FrensListItem"
import {User} from "../../redux/types";
import { List, AutoSizer } from 'react-virtualized';

interface FrensListProps {
    arrayItems: User[];
}

const FrensList: React.FC<FrensListProps> = ({ arrayItems }) => {


    const rowRenderer = ({ key, index, style }: { key: string; index: number; style: React.CSSProperties }) => {
        const item = arrayItems[index];
        return (
            <div key={key} style={style}>
                <FrensListItem key={index} item={item} index={index} arrLength={arrayItems.length} />
            </div>
        );
    };


    const rowHeight = ({ index }: { index: number }) => {
        const baseHeight = window.innerHeight > 730 ? window.innerHeight * 0.064 : window.innerHeight * 0.0727;
        // Если это последний элемент, удваиваем высоту
        return index === arrayItems.length - 1 ? baseHeight * 1.65 : baseHeight;
    };

    return (
        <div className={s.list}>
            <AutoSizer>
                {({ height, width }) => {
                    return (
                        <List
                            width={width}
                            height={height || window.innerHeight}
                            rowCount={arrayItems.length}
                            rowHeight={rowHeight}
                            rowRenderer={rowRenderer}
                        />
                    );
                }}
            </AutoSizer>
        </div>
    )
}

export default FrensList