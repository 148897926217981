import React, {useState} from 'react';
import s from './SecretCode.module.css'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";

interface KeypadProps {
    isClaimed: boolean;
}

const Keypad: React.FC<KeypadProps> = ({isClaimed}) => {
    const dispatch = useDispatch();
    const promoResult = useSelector((state: RootState) => state.promo.claimPromoResult);
    const [input, setInput] = useState<string>('');

    const handleButtonClick = (value: string) => {
        if (input.length === 6) return;

        setInput(input + value);
    };

    const handleBackspace = () => {
        setInput(input.slice(0, -1));
    };

    const handleEnter = () => {
        if(isClaimed) return;

        // Add your logic here
        dispatch({type: 'promo/claimPromo', payload: input});
    };
    return (
        <div className={s.keypadContainer}>

            <img className={s.safeImg} src={`/images/keypad/safe.webp`} alt="safe" />
            <div className={s.keypad}>
                <img className={s.sheepImg} src={`/images/keypad/sheep.webp`} alt="sheep" />


                <div className={s.display}>
                    {Array.from({ length: 6 }).map((_, index) => (
                        // s.slot_default || s.slot_blue || s.slot_red
                        <div key={index} className={`${s.slot} ${promoResult === -1 ?s.slot_default: (promoResult? s.slot_blue: s.slot_red)}`}>
                            {input[index] || '_'}
                        </div>
                    ))}
                </div>

                <img className={s.divider} src={`/images/keypad/divider.webp`} alt="divider" />

                <div className={s.buttons}>
                    {['1', '2', '3', '4', '5', '6', '7', '8', '9', "←", '0'].map((number, index) => (
                        <button key={index}
                            className={s.button}
                            onClick={index === 9 ? handleBackspace : () => handleButtonClick(number)}
                            type="button">
                            {/* {number} */}
                            {index === 9 ? <img className={s.backspace} src={`/images/keypad/backspace.webp`} alt="backspace" /> : number}
                        </button>
                    ))}
                    <button disabled={isClaimed} onClick={handleEnter} className={`${s.buttonEnter} ${isClaimed? s.buttonOpacity: ''}`} type="button">Enter</button>
                </div>


            </div>
        </div>
    );
};

export default Keypad;
