import s from './ProgressBar.module.css'

interface ProgressBarProps {
    current: number;
    max: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ current, max }) => {

    const completeWidth = (current: number, max: number) => current / max * 33.33

    return (
        <div className={`${s.barContainer} ${s.barProgress}`}>
            <div className={`${s.barContainer} ${s.barComplete}`}
                style={{ width: `${completeWidth(current, max)}vw` }}
            >
                <p className={s.mainText}>{current} / {max}</p>
            </div>
        </div>
    )
}

export default ProgressBar