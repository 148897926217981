// src/redux/sagas/index.ts
import { all, fork } from 'redux-saga/effects';
// Import your individual sagas here
import gameSaga from './gameSaga';
import boostSaga from './boostSaga'; // Import the earn saga
import leaderboardSaga from './leaderboardSaga';
import tasksSaga from "./tasksSaga";
import promoSaga from "./promoSaga"; // Import the airdrop saga

export default function* rootSaga() {
    yield all([
        fork(gameSaga),
        fork(boostSaga),
        fork(tasksSaga),
        fork(leaderboardSaga),
        fork(promoSaga),
    ]);
}
