// SimpleButton.tsx
import React from 'react';
import s from './SimpleButton.module.css';

interface SimpleButtonProps {
    imageUrl: string;
    className?: string;
    width?: number;
    onClick: () => void;
    children?: React.ReactNode;
}

const SimpleButton: React.FC<SimpleButtonProps> = ({ imageUrl, className, width , onClick, children }) => {

    const style: {[key: string]: any} = {
        backgroundImage: `url(${imageUrl})`
    };
    if(width) style['width'] = `${width}vw`;

    return (
        <button
            className={className? className: s.btn}
            onClick={onClick}
            style={style}
            type="button"
        >{children}</button>
    );
};

export default SimpleButton;
