import SimpleButton from '../common/SimpleButton'
import s from './CheatList.module.css'
import {Task, TaskStatus} from "../../redux/types";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";

const getIcon = (item: Task | undefined): any => {
    if(!item) return '';

    switch (item.icon) {
        case "tg": return `/images/tasks/tg.webp`;
    }
}

const CheatListItem: React.FC<{ item: TaskStatus, taskId: number, isLast: boolean, onTaskClick: (task: Task|undefined) => void }> = ({ item, taskId, isLast, onTaskClick }) => {
    const {tasks} = useSelector((state: RootState) => state.tasks);

    const task = tasks.find((el) => el.id === taskId);


    return (
        <div className={s.listItemBox}>
            {isLast && <img className={s.ropesBG} src={`/images/ropes.webp`} alt="ropes" />}
            <img className={s.alarmImg} src={`/images/cheat_alarm.webp`} alt="alarm" />

            <div className={s.listItem}>
                <div className={s.avaPanel}>
                    <img className={s.baseImg} src={getIcon(task)} alt="avatar" />
                </div>
                <div className={s.textContainer}>
                    <p className={s.titleText}>{task!.title}</p>
                    <p className={s.botText}>please, resubscribe</p>
                </div>
                <SimpleButton imageUrl={`/images/tasks/play.webp`} width={9.4} onClick={() => { onTaskClick(task) }} />
            </div>


        </div>
    )
}

export default CheatListItem