import NavItem from "./NavItem";
import s from "./BottomNavigation.module.css";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentModal} from "../../redux/slices/gameSlice";
import {RootState} from "../../redux/store";

const BottomNavigation: React.FC = () => {
    const dispatch = useDispatch();

    const handleImageClick = (modal: string) => dispatch(setCurrentModal(modal));
    const currentModal = useSelector((state: RootState) => state.game.currentModal)

  return (
    <nav className={s.bottomNav}>
      <NavItem
        path="/"
        name="frens"
        imgPath={`/images/tabbar/ic_tabbar_frens_act.webp`}
        isActive={currentModal === 'frens'}
        onClick={() => handleImageClick('frens')}
      />
      <NavItem
        path="/"
        name="tasks"
        imgPath={`/images/tabbar/ic_tabbar_earn_def.webp`}
        isActive={ currentModal === 'tasks'}
        onClick={() => handleImageClick('tasks')}
      />
      <NavItem
        path="/"
        name="game"
        imgPath={`/images/tabbar/ic_tabbar_game_act.webp`}
        isActive={currentModal === ''}
        onClick={() => handleImageClick('')}
      />
      <NavItem
        path="/"
        name="boost"
        imgPath={`/images/tabbar/ic_tabbar_boost_def.webp`}
        isActive={currentModal === 'boost'}
        onClick={() => handleImageClick('boost')}
      />
      <NavItem
        path="/"
        name="leaders"
        imgPath={`/images/tabbar/ic_tabbar_rating_def.webp`}
        isActive={currentModal === 'leaders'}
        onClick={() => handleImageClick('leaders')}
      />
    </nav>
  );
};

export default BottomNavigation;
