// src/redux/sagas/apiCaller.ts
import {call, delay, put, select, take, actionChannel} from 'redux-saga/effects';
import * as api from '../../services/api';
import {ApiFunctions} from '../../services/api';
import {AxiosError, isAxiosError} from "axios";
import {
    setAuthorization,
    setRunningRequest,
    setAuthTokens,
    setIsNetworkError,
    setSendRequestFunc, setSendRequestData
} from "../slices/gameSlice";
import {RootState} from "../store";
import telegramWebAppAPI from "../../services/telegram";

const MAX_RETRIES = 3;

interface ApiCallerArgs {
    apiFuncName: keyof ApiFunctions;
    args: any;
}

export function* apiCaller({ apiFuncName, args }: ApiCallerArgs): Generator<any, any, any> {
    let userCalledFn = apiFuncName !== 'fetchProfile';
    if(userCalledFn) yield put(setRunningRequest(true));

    const apiFunctions = api.apiFunctionMap;
    let func = apiFuncName;
    let funcArgs = args;

    const retryChannel = yield actionChannel('retryChannel')

    let retries = 0;
    while(true) {
        retries += 1;

        // sleep forever
        if (retries > MAX_RETRIES) {
            yield put(setRunningRequest(false));
            yield take(retryChannel);
        }

        try {
            // console.log('func', func, 'funcArgs', funcArgs, 'retry cnt', retries);

            const response = yield call(apiFunctions[func], funcArgs);

            if(userCalledFn) yield put(setRunningRequest(false));
            yield put(setIsNetworkError(false));

            return response;
        } catch (error) {
            if (isAxiosError(error) && error.response && error.response.status === 401) {

                let tokens = {access_token: "", refresh_token: "", expires_in: 0};
                try {
                    // Unauthorized, re-authorize
                    tokens = yield call(api.refresh);
                } catch (e) {
                    try {
                        yield delay(100);
                        let data = telegramWebAppAPI.getData();
                        tokens = yield call(api.authorize, {data: data})
                    } catch (e) {
                        yield put(setRunningRequest(false));
                    }
                }

                if(tokens.access_token && tokens.refresh_token) {
                    yield put(setAuthTokens(tokens));
                    yield put(setAuthorization(true));
                }
                // Update the tokens in the state (assuming you have a way to update the state here)
                // Retry API call with new tokens
                const response = yield call(apiFunctions[func], funcArgs);
                if(userCalledFn) yield put(setRunningRequest(false));
                yield put(setIsNetworkError(false));

                return response;
            } if (isInternetDisconnectedError(error)) {
                if(retries >= MAX_RETRIES) {
                    yield put(setIsNetworkError(true));
                    yield put(setSendRequestFunc(apiFuncName));
                    yield put(setSendRequestData(args));
                    yield put(setRunningRequest(false));
                }
                yield delay(200);
            } else {
                if(userCalledFn) yield put(setRunningRequest(false));
                yield put(setIsNetworkError(false));
                throw error;
            }
        }
    }
}

function isInternetDisconnectedError(error: any): boolean {
    if (error instanceof AxiosError) {
        return error.code === "ERR_NETWORK"
    }
    return false;
}

export function* waitForAuthorization() {
    while (true) {
        const isAuthorized: boolean = yield select((state: RootState) => state.game.isAuthorized);
        if (isAuthorized) break;
        yield take('game/setAuthorization');
    }
}
