// src/redux/sagas/promoSaga.ts
import {takeEvery, call, put, delay, takeLatest, Effect} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {apiCaller, waitForAuthorization} from "./apiCaller";
import {fetchUpdates} from "./gameSaga";
import {setClaimPromoResult, setLoadedAt, setPromo} from "../slices/promoSlice";

function* handleFetchPromo(): Generator<any, void, any> {
    // Wait until the authorization is complete
    yield waitForAuthorization();

    try {
        const promo = yield call(apiCaller, { apiFuncName: 'fetchPromo', args: {}});
        yield put(setPromo(promo));
        yield put(setLoadedAt(new Date().getTime()));
    } catch (error) {
        console.error(error);
    }
}

function* handleClaimPromo(action: PayloadAction<string>): Generator<any, void, any> {
    try {
        const {result, promo} = yield call(apiCaller, { apiFuncName: 'claimPromo', args: action.payload});
        yield put(setClaimPromoResult(result?1:0));

        if(promo) {
            yield put(setPromo(promo));
            yield put(setLoadedAt(new Date().getTime()));
        }
        yield call(fetchUpdates);
    } catch (error) {
        console.error(error);
    }
}

export function* everyMinute() {
    try {
        yield delay(50000);
        yield put({ type: "promo/fetchPromo" })
        yield put({ type: "promo/everyMinute" })
    } catch (e) {
        console.error(e);
    }
}

export default function* promoSaga(): Generator<Effect, void, unknown> {
    yield takeEvery('promo/fetchPromo', handleFetchPromo);
    yield takeEvery('promo/claimPromo', handleClaimPromo);
    yield takeLatest('promo/everyMinute', everyMinute)
}
