// src/pages/BoostPage.tsx
import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import s from "./BoostPage.module.css"
import BoostList from '../../components/BoostList';
import PageContainer from '../../components/common/PageContainer';
import { Boost, BoostStatus, BoostType } from "../../redux/types";
import { telegramShare } from "../../services/telegram";
import {setClaimBoostResult} from "../../redux/slices/boostSlice";
import {showToast} from "../../redux/slices/gameSlice";

const BoostPage: React.FC = () => {
    const dispatch = useDispatch();

    const { boosts } = useSelector((state: RootState) => state.boost);
    const user = useSelector((state: RootState) => state.game.user);
    const claimResult = useSelector((state: RootState) => state.boost.claimBoostResult);

    const boostsStatuses = new Map<string, BoostStatus>(Object.entries(user.boosts));

    const availableBoosts = boosts.filter((el) => !boostsStatuses.has(String(el.id)) || boostsStatuses.get(String(el.id))?.status === 0);
    const claimedBoosts = boosts.filter((el) => boostsStatuses.has(String(el.id)) && boostsStatuses.get(String(el.id))?.status === 1);

    useEffect(() => {
        if(claimResult !== 1) return;

        dispatch(showToast('Boost claimed!'));
        dispatch(setClaimBoostResult(-1));
    }, [dispatch, claimResult]);

    const onClaimBoost = (boost: Boost) => {
        const boostStatus = boostsStatuses.get(String(boost.id));

        if (boostStatus?.status === 1) return;

        if (!boostStatus || boostStatus.count < boost.max_count) {
            if (boost.type === BoostType.FRIENDS) {
                const { handleGenerateLink } = telegramShare(user)
                handleGenerateLink();
            }

            return;
        }

        dispatch({ type: 'boost/claimBoost', payload: boost.id });
    };

    return (
        <PageContainer>
            <div className={s.scrollContainer}>
                <h1 className={s.text} >YOUR BOOSTER ZONE</h1>

                {availableBoosts.length > 0
                    ? <BoostList arrayItems={availableBoosts} status={boostsStatuses} onBoostClick={onClaimBoost} />
                    : <div className={s.stayTunedWrapper}>
                        <img className={s.stayTunedImg} src={"/images/stay_tuned_boosts.webp"} alt="stay_tuned" />
                    </div>
                }

                {claimedBoosts.length > 0 && <div className={s.titleOnBase}>
                    <p className={s.text}>claimed</p>
                </div>
                }

                <BoostList arrayItems={claimedBoosts} status={boostsStatuses} onBoostClick={onClaimBoost} />
            </div>
        </PageContainer>

    );
};

export default BoostPage;
