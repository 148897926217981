import { useEffect, useState } from "react"
import ProgressBar from "../common/ProgressBar";
import s from './BoostList.module.css'
import {Boost, BoostStatus, BoostType} from "../../redux/types";
import Spinner from "../common/Spinner";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import CountdownTimer from "./CountdownTimer";

const BoostListItem: React.FC<{ item: Boost, boostStatus: BoostStatus | undefined, onBoostClick: (boost: Boost) => void; index: number, arrLength: number }> = ({ item, onBoostClick, boostStatus, index, arrLength }) => {
    const runningRequest = useSelector((state: RootState) => state.game.runningRequest);
    let currentProgress = boostStatus ? boostStatus.count : 0;
    const completed = currentProgress >= item.max_count;
    const claimed = boostStatus ? boostStatus.status === 1 : false;
    if(currentProgress > item.max_count) currentProgress = item.max_count;

    // === LOADER CLICK ======
    const [isClicked, setIsClicked] = useState(false);
    useEffect(() => {
        if (!runningRequest) setIsClicked(false);
    }, [runningRequest]);

    const handleButtonClick = () => {
        setIsClicked(true);
        onBoostClick(item);
    };

    return (
        <div className={s.listItemBox}>
            {index < arrLength - 1 && <img className={s.ropesBG} style={{ animationDelay: `${index * 0.01}s` }} src={`/images/ropes.webp`} alt="ropes" />}

            {claimed && <div className={s.opacity}></div>}

            <div className={`${s.listItem} ${claimed ? s.listItemDone : ''}`} style={{ animationDelay: `${index * 0.01}s` }}>
                <div className={s.rewardBox}>
                    <img className={s.energyImg} src={`/images/boost/energy.webp`} alt="energy" />
                    <p className={s.reward}>{item.description}</p>
                </div>

                <div className={s.progressBarContainer}>
                    <p className={s.mainText}>{item.title}</p>
                    {/* todo: add current progress */}
                    {claimed && boostStatus && item.type === BoostType.DAILY_ENERGY && <CountdownTimer secondsPassed={boostStatus?.seconds_passed} />}
                    {item.type !== BoostType.DAILY_ENERGY && <ProgressBar current={currentProgress} max={item.max_count} />}
                </div>

                {/* todo: add condition */}

                {/* checkbox */}
                {completed && claimed && <img className={s.checkedImg} src={`/images/btn_checked.webp`} alt="checked" />}

                {/* yellow CLAIM! btn */}
                {completed && !claimed && <button
                    className={`${s.btn} ${s.btnClaim}`}
                    onClick={handleButtonClick}
                    type="button"
                    disabled={!completed || isClicked}
                >
                    {isClicked ? <Spinner /> : <p className={s.mainText}>{"claim!"}</p>}
                </button>}

                {/* blue GO! btn */}
                {!claimed && !completed &&
                    // <button
                    //     className={`${s.btn} ${s.btnGo}`}
                    //     onClick={() => { onBoostClick(item); }}
                    //     type="button"
                    // >
                    //     <p className={s.mainText}>{"go!"}</p>
                    // </button>

                    <button
                        className={`${s.btn} ${s.btnGo}`}
                        onClick={handleButtonClick}
                        type="button"
                    >
                        <p className={s.mainText}>{"go!"}</p>
                    </button>
                }

            </div>
        </div >
    )
}

export default BoostListItem