import s from './LoadScreen.module.css'
import ProgressBarMain from '../common/ProgressBarMain';
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const LoadScreen: React.FC = () => {
    const { loadProgress } = useSelector((state: RootState) => state.game);

    const completeWidth = (current: number, max: number) => current / max * 40.83
    const currentWidth = completeWidth(loadProgress, 100)

    return (
        <div className={s.container}>
            <div className={s.titleBox}>
                <img className={s.heroImg} src={`/images/loading_hero_title.webp`} alt="hero" />
            </div>

            <div className={s.sheepBox}>
                <img className={s.sheepImg} src={`/images/loading_sheep.webp`} alt="sheep" />
            </div>
            <div className={s.progressBarBox}>
                <div className={s.progressBarScale}>
                    <ProgressBarMain img={"scissors"} completeWidth={currentWidth} barText={"Loading..."} />
                </div>
            </div>
        </div>
    )
}

export default LoadScreen