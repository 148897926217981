import s from "./TasksList.module.css"
import SimpleButton from "../common/SimpleButton";
import { Task } from "../../redux/types";

const getIcon = (item: Task): any => {
    switch (item.icon) {
        case "tg": return `/images/tasks/tg.webp`;
    }
}

interface TasksListItemProps {
    item: Task;
    completed: boolean;
    index: number;
    arrLength: number;
    onTaskClick: (task: Task) => void;
}

const TasksListItem: React.FC<TasksListItemProps> = ({ item, completed, index, arrLength, onTaskClick }) => {

    return (
        <div className={s.listItemBox}>
            {completed && <div className={s.opacity}></div>}

            {index < arrLength - 1 && <img className={s.ropesBG}
                style={{ animationDelay: `${index * 0.01}s` }}
                src={`/images/ropes.webp`} alt="ropes" />}

            {completed && <div className={s.opacity}></div>}

            <div className={s.listItem} style={{ animationDelay: `${index * 0.01}s` }}>
                <div className={s.avaPanel}>
                    <img className={s.baseImg} src={getIcon(item)} alt="avatar" />
                </div>
                <div className={s.textContainer}>
                    <p className={s.taskText}>{item.title}</p>
                    <div className={s.rewardBox}>
                        <img className={s.coinImg} src={`/images/header/coin.webp`} alt="coin" />
                        <p className={s.reward}>+{item.reward}</p>
                    </div>
                </div>

                {completed
                    ? <img className={s.checkedImg} src={`/images/btn_checked.webp`} alt="checked" />
                    : <SimpleButton imageUrl={`/images/tasks/play.webp`} width={9.4} onClick={() => onTaskClick(item)} />
                }
            </div>
        </div>
    )
}


export default TasksListItem