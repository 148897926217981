// ImageComponentBG.tsx
import React from 'react';

interface ImageComponentBGProps {
    className?: string;
    imageUrl: string;
    children?: React.ReactNode;
    style?: React.CSSProperties;
}

const ImageComponentBG: React.FC<ImageComponentBGProps> = ({ className = '', imageUrl, children, style }) => {

    return (
        <div
            className={className}
            style={{
                ...style,
                backgroundImage: `url(${imageUrl})`,
            }}
        >
            {children}
        </div>
    );
};

export default ImageComponentBG;