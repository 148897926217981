import s from './ProgressBarMain.module.css'

interface ProgressBarMainProps {
    img: string
    completeWidth: number
    barText: string
}

const ProgressBarMain: React.FC<ProgressBarMainProps> = ({ img, completeWidth, barText }) => {
    // const { images } = useImageContext();
    return (
        <div className={s.barContainer}>
            {img === "energy" && <img className={s.imgEnergy} src={`/images/game_energy.webp`} alt="energy" />}
            {img === "scissors" && <img className={s.imgScissors} src={`/images/scissors.webp`} alt="scissors" />}
            <div className={s.barComplete}
                style={{ width: `${completeWidth}vw` }}
            >
            </div>
            <img className={s.barPanelTop} src={`/images/game_progress_panel_top.webp`} alt="barPanelTop" />
            <p className={s.barText}>{barText}</p>
        </div>
    )
}

export default ProgressBarMain