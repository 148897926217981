// TasksList.tsx
import React from "react";
import TasksListItem from "./TasksListItem";
import s from "./TasksList.module.css";
import { Task } from "../../redux/types";

interface TasksListProps {
    arrayItems: Task[];
    completed: boolean;
    onTaskClick: (task: Task) => void;
}

const TasksList: React.FC<TasksListProps> = ({ arrayItems, onTaskClick, completed }) => {
    return (

        <div className={s.list} style={completed ? { paddingBottom: '15vh' } : {}}>
            {
                arrayItems.map((item, index) => (
                    <TasksListItem key={index} item={item} completed={completed} index={index} arrLength={arrayItems.length} onTaskClick={onTaskClick} />
                ))
            }
        </div >

    );
};

export default TasksList;
