import React, { useState } from "react";
import TasksList from "../../components/TasksList";
import s from "./TasksPage.module.css";
import PageContainer from "../../components/common/PageContainer";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Task } from "../../redux/types";
import Modal from "../../components/common/Modal";
import ModalTasksContent from "../../components/ModalTasksContent";
import useModal from "../../services/useModal";

const TasksPage: React.FC = () => {

    const { tasks } = useSelector((state: RootState) => state.tasks);
    const { user } = useSelector((state: RootState) => state.game);

    const taskStatus = new Map<number, number>();

    if(user.completed_tasks) {
        user.completed_tasks.forEach((taskId) => {
            taskStatus.set(taskId, taskId);
        })
    }

    const newTasks = tasks.filter((el) => !taskStatus.has(el.id));
    const doneTasks = tasks.filter((el) => taskStatus.has(el.id));

    const [selectedTask, setSelectedTask] = useState<Task | null>(null);
    const { isOpen, isFadingOut, openModal, closeModal } = useModal();

    const handleOpenModal = (task: Task) => {
        setSelectedTask(task);
        openModal();
    };

    return (
        <PageContainer>
            <div className={s.scrollContainer} >
                <h1 className={s.text} >COMPLETE THE TASK AND GET COINS</h1>

                {/* <SkeletonThin count={5} /> */}
                {newTasks.length > 0
                    ? <TasksList arrayItems={newTasks} onTaskClick={handleOpenModal} completed={false} />
                    : <div className={s.stayTunedWrapper}>
                        <img className={s.stayTunedImg} src={"/images/stay_tuned.webp"} alt="stay_tuned" />
                    </div>

                }

                <div className={s.titleOnBase}>
                    <p className={s.text}>completed</p>
                </div>

                {/*<SkeletonWide count={5} />*/}
                <TasksList arrayItems={doneTasks} onTaskClick={handleOpenModal} completed={true} />
            </div>

            {isOpen && selectedTask && <Modal closeModal={closeModal} isFadingOut={isFadingOut} blocking={true} >
                <ModalTasksContent task={selectedTask} closeModal={closeModal} />
            </Modal>}
        </PageContainer>
    );
};

export default TasksPage;