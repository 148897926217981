import s from './About.module.css'
import React from "react";

interface AboutListProps {
    arrayItems: {
        title: string;
        text: string;
        icon: any;
    }[];
}

const AboutList: React.FC<AboutListProps> = ({ arrayItems }) => {
    const getListItemClassNamePos = (index: number) => {
        switch (index) {
            case 0:
                return s.goldPos;
            case 1:
                return s.silverPos;
            case 2:
                return s.bronzePos;
            default:
                return s.lowRatingPos;
        }
    };
    return (

        <div className={s.list}>

            {arrayItems.map((item, index) => (
                <div key={index} className={s.listItem}>
                    {index < arrayItems.length - 1 && <img className={s.ropesBG} src={`/images/ropes_wide.webp`} alt="ropes" />}

                    <img className={s.avaImg} src={item.icon} alt="avatar" />

                    <div className={s.textContainer}>
                        <p className={`${s.titleList} ${getListItemClassNamePos(index)}`}>{item.title}</p>
                        <p className={s.text}>{item.text}</p>
                    </div>
                </div>
            ))}
        </div >
    )
}

export default AboutList