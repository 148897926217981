import SimpleButton from "../common/SimpleButton";
import aboutData from "./about-data.js"
import s from './About.module.css'
import AboutList from "./AboutList";
import telegramWebAppAPI from "../../services/telegram";
import React from "react";

interface AboutProps {
    closeModal: () => void;
}

const About: React.FC<AboutProps> = ({ closeModal }) => {
    return (
        <div className={s.container}>
            <div className={s.btnClose}>
                <SimpleButton imageUrl={`/images/modalTasksContent/btn_close.webp`} width={8.89} onClick={closeModal} />
            </div>
            <div className={s.sheepBox}>
                <img className={s.sheepImg} src={`/images/about/about_sheep.webp`} alt="sheep" />
                <img className={s.welcomeImg} src={`/images/about/about_welcome.webp`} alt="welcome" />
            </div>

            <div className={s.textBox}>
                <p className={s.text}>B<span className={s.textLowercase}>aa</span>T<span className={s.textLowercase}>ap</span>B<span className={s.textLowercase}>ot</span>
                    - telegram bot, where you can shear the legendary Black Sheep & earn coins! Join the herd and let's make money together!</p>
            </div>

            <div className={s.isideContainer}>
                <AboutList arrayItems={aboutData} />

                <div className={s.footer}>
                    <img className={s.ropesShortBG} src={`/images/ropes_short.webp`} alt="ropes" />
                    <SimpleButton imageUrl={`/images/tasks/tg.webp`} width={11.11} onClick={() => { telegramWebAppAPI.openTelegramLink('https://t.me/Baa_Meme') }} />

                    <button className={s.playBtn} onClick={() => { closeModal() }} type="button">
                        <img className={s.scissorsImg} src={`/images/scissors.webp`} alt="scissors" />
                        <p className={s.textBtn}>play</p>
                    </button>

                    <SimpleButton imageUrl={`/images/tasks/twitter.webp`} width={11.11} onClick={() => { telegramWebAppAPI.openLink('https://x.com/Baa_Meme') }} />
                </div>
            </div>
            <p className={s.versionBox}>v1.1.4</p>
        </div>
    )
}

export default About