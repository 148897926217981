// ImageComponent.tsx
import React from 'react';

interface ImageComponentProps {
    className?: string;
    src: string;
    alt: string;
}

const ImageComponent: React.FC<ImageComponentProps> = ({ className = '', src, alt }) => {

    return (
        <img className={className} src={src} alt={alt} />
    );
};

export default ImageComponent;