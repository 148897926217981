// src/components/NavigationHandler.tsx
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import telegramWebAppAPI from "../services/telegram";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {setCurrentModal} from "../redux/slices/gameSlice";

const NavigationHandler: React.FC = () => {
    const navigate = useNavigate();
    const currentModal = useSelector((state: RootState) => state.game.currentModal);
    const dispatch = useDispatch();

    useEffect(() => {
        telegramWebAppAPI.onBackClick(() => {
            if(currentModal === '')
                navigate(-1);
            else dispatch(setCurrentModal(""));
        });
    }, [currentModal, dispatch, navigate]);


    useEffect(() => {
        telegramWebAppAPI.selectionChanged();
        if (currentModal === "") {
            telegramWebAppAPI.hideBackButton();
        } else {
            telegramWebAppAPI.showBackButton();
        }
    }, [currentModal, dispatch]);

    return null;
};

export default NavigationHandler;
