import SimpleButton from '../common/SimpleButton'
import Keypad from './Keypad'
import s from './SecretCode.module.css'
import {useDispatch, useSelector} from "react-redux";
import {showPromo} from "../../redux/slices/gameSlice";
import {RootState} from "../../redux/store";
import useModal from "../../services/useModal";
import Modal from "../common/Modal";
import React, {useEffect} from "react";
import ModalPromoSuccess from "../ModalPromoSuccess";
import ModalPromoLimit from "../ModalPromoLimit";
import {setClaimPromoResult} from "../../redux/slices/promoSlice";
import CountdownTimer from "./CountdownTimer";
const MAX_ATTEMPTS = 3;

const SecretCode = () => {
    const dispatch = useDispatch();
    const promo = useSelector((state: RootState) => state.promo.promo);
    const user = useSelector((state: RootState) => state.game.user);
    const availableAttempts = MAX_ATTEMPTS - (user.promo_attempts? user.promo_attempts: 0);
    const usedAttempts = user.promo_attempts? user.promo_attempts: 0;
    const promoResult = useSelector((state: RootState) => state.promo.claimPromoResult);
    const loadedAt = useSelector((state: RootState) => state.promo.loadedAt);
    const actualSecondsLeft = promo.seconds_left - ((new Date().getTime() - loadedAt) / 1000);

    const claimedPromo = user.promos_claimed && !!user.promos_claimed.find((e) => e === promo.id);

    const { isOpen: isOpenSuccess,
        isFadingOut: isFadingSuccess,
        openModal: openModalSuccess,
        closeModal: closeModalSuccess } = useModal();

    const { isOpen: isOpenLimit,
        isFadingOut: isFadingLimit,
        openModal: openModalLimit,
        closeModal: closeModalLimit } = useModal();


    useEffect(() => {
        if(promoResult === -1) return;

        if(promoResult === 1) openModalSuccess();
        else if(!promoResult && availableAttempts === 0) openModalLimit();

    }, [dispatch, promoResult, availableAttempts, openModalSuccess, openModalLimit]);

    const closeSuccessModal = () => {
        dispatch(setClaimPromoResult(-1)); closeModalSuccess();
    };

    const closeLimitModal = () => {
        dispatch(setClaimPromoResult(-1)); closeModalLimit();
    }

    return (
        <div className={s.container}>
            <div className={s.btnClose}>
                <SimpleButton imageUrl={`/images/modalTasksContent/btn_close.webp`} width={8.89} onClick={() => { dispatch(showPromo(0)) }} />
            </div>

            <div className={s.titleBox}>
                <img className={s.woodenPlate} src={`/images/header/wooden_plate.webp`} alt="wooden_plate" />
                <img className={s.paper} src={`/images/header/paper.webp`} alt="paper" />
                <p className={s.titleHeader}>secret code</p>
            </div>

            <div className={s.mainTextBox}>
                <p className={s.textSimple}>Get the secret code from a friend, on the</p>
                <p className={s.textSimple}>official $BAA pages or on partner channels</p>
            </div>

            <Keypad isClaimed={claimedPromo} />

            <div className={s.footer}>
                <div className={s.footerMargin}>
                    <div className={s.footerBox} style={{}}>
                        <p className={s.footerTitle}>Limited time:</p>
                        <p className={s.footerText}><CountdownTimer seconds={actualSecondsLeft} /></p>
                    </div>
                    <div className={s.footerBox} style={{}}>
                        <p className={s.footerTitle}>YOUR Attempts:</p>
                        <div className={s.attemptsBox}>
                            {[...Array(availableAttempts)].map((x, i) => <img key={i} className={s.footerImg}
                                                                              src={`/images/keypad/heart.webp`} alt="heart"/>)}
                            {[...Array(usedAttempts)].map((x, i) => <img key={i} className={s.footerImg}
                                                                              src={`/images/keypad/heart_opacity.webp`} alt="heart"/>)}
                        </div>
                    </div>
                    <div className={s.footerBox} style={{}}>
                        <p className={s.footerTitle}>opened safes</p>
                        <p className={s.footerText}>{promo.uses}/{promo.max_uses}</p>
                    </div>
                </div>
            </div>


            {isOpenSuccess && <Modal closeModal={closeSuccessModal} isFadingOut={isFadingSuccess} blocking={true}>
                <ModalPromoSuccess reward={promo.reward} closeModal={closeSuccessModal} />
            </Modal>}

            {isOpenLimit && <Modal closeModal={closeLimitModal} isFadingOut={isFadingLimit} blocking={true}>
                <ModalPromoLimit closeModal={closeLimitModal} />
            </Modal>}

        </div>
    )
}

export default SecretCode