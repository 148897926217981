import React, {useState, useEffect, useRef, useMemo, useCallback} from "react";
import s from "./Header.module.css";
import SimpleButton from "../common/SimpleButton";
import {useDispatch, useSelector} from "react-redux";
import { RootState } from "../../redux/store";
import Modal from "../common/Modal";
import About from "../About";
import ModalChannelContent from "../ModalChannelContent";
import useModal from "../../services/useModal";
import SecretCode from "../SecretCode";
import {showPromo} from "../../redux/slices/gameSlice";

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const currentModal = useSelector((state: RootState) => state.game.currentModal);
  const { user } = useSelector((state: RootState) => state.game);


  const [isPlaying, setIsPlaying] = useState(false);
  const audioContainerRef = useRef<HTMLDivElement>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    // Create the audio element
    const audio = document.createElement('audio');
    audio.loop = true;

    // Create source elements for .ogg and .mp3
    const sourceOgg = document.createElement('source');
    sourceOgg.src = '/audio/bg.ogg';
    sourceOgg.type = 'audio/ogg';

    const sourceMp3 = document.createElement('source');
    sourceMp3.src = '/audio/bg.mp3';
    sourceMp3.type = 'audio/mpeg';

    // Append sources to the audio element
    audio.appendChild(sourceOgg);
    audio.appendChild(sourceMp3);

    // Store a reference to the audio element
    audioRef.current = audio;

    // Capture the current value of the ref
    const audioContainer = audioContainerRef.current;
    if (audioContainer) {
      audioContainer.appendChild(audio);
    }

    // Cleanup on unmount
    return () => {
      audio.pause();
      if (audioContainer) {
        audioContainer.removeChild(audio);
      }
    };
  }, []);

  const toggleAudio = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch(() => { })
      }
      setIsPlaying(!isPlaying);
    }
  };

  const soundImg = isPlaying ? `/images/header/ic_sound.webp` : `/images/header/ic_sound_off.webp`;

  const titleNormalize = (testTitle: string | number): string =>
    typeof testTitle === "number" ? testTitle.toLocaleString("en-US") : testTitle.toString();


  const headerText = useCallback((): string => {
    switch (currentModal) {
      case 'frens':
        return 'Friends';
      case 'leaders':
        return 'Leaders';
      case 'boost':
        return 'Boost';
      case 'tasks':
        return 'Tasks';
    }
    return '';
  }, [currentModal])
  const isTasksHeader = useMemo(() => headerText() === "Tasks", [headerText]);

  // path
  const gamePath = currentModal === ""

  // ==== MODAL About=====
  const { isOpen: isModalOpenAbout,
    isFadingOut: isFadingOutAbout,
    openModal: openModalAbout,
    closeModal: closeModalAbout } = useModal();

  // ==== MODAL Channel=====
  const { isOpen: isModalOpenChannel,
    isFadingOut: isFadingOutChannel,
    openModal: openModalChannel,
    closeModal: closeModalChannel } = useModal();

  const promo = useSelector((state: RootState) => state.promo.promo);
  const isShowPromo = useSelector((state: RootState) => state.game.showPromo);
  const showPromoModal = isShowPromo >= 5 && promo.max_uses > 0;

  return (
    <>
      {showPromoModal && <Modal blocking={true}><SecretCode /></Modal>}

      <header className={s.headerContainer}>
        <div ref={audioContainerRef} />
        {gamePath && <SimpleButton imageUrl={soundImg} width={13.33} onClick={() => toggleAudio()} />}
        {/* {isTasksHeader && <div style={{ width: "13.33vw" }}></div>} */}

        {/* Game Page */}
        {gamePath && <div className={`${s.titleBox}`} onTouchStart={() => {dispatch(showPromo(1));}}>
          <img className={s.woodenPlate} src={`/images/header/wooden_plate.webp`} alt="wooden_plate" />
          <img className={s.coinsBase} src={`/images/header/coins_base.webp`} alt="title_base" />
          <img className={s.coin} src={`/images/header/coin.webp`} alt="coin" />
          <p className={s.titleGame}>{titleNormalize(user.tokens)}</p>
        </div>}

        {/* Other Pages */}
        {!gamePath && <div className={`${s.titleBox}`}>
          <img className={s.woodenPlate} src={`/images/header/wooden_plate.webp`} alt="wooden_plate" />
          <img className={s.paper} src={`/images/header/paper.webp`} alt="paper" />
          <p className={s.title}>{titleNormalize(headerText())}</p>
        </div>}

        {gamePath &&
          <SimpleButton imageUrl={`/images/header/ic_info.webp`} width={13.33} onClick={() => openModalAbout()} />}

        {isTasksHeader &&
          <div className={s.btnAbsolute}>
            <SimpleButton imageUrl={`/images/task_channel_btn.webp`} width={18} onClick={openModalChannel} />
          </div>
        }

        {/* MODAL About */}
        {isModalOpenAbout && <Modal closeModal={closeModalAbout} isFadingOut={isFadingOutAbout} blocking={true}>
          <About closeModal={closeModalAbout} />
        </Modal>}

        {/* MODAL Channel */}
        {isModalOpenChannel && <Modal closeModal={closeModalChannel} isFadingOut={isFadingOutChannel} blocking={true}>
          <ModalChannelContent closeModal={closeModalChannel} />
          {/* <TechWorks /> */}
        </Modal>}
      </header>

      <div className={s.titleBox}></div>
    </>
  );
};

export default Header;
