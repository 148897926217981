import SimpleButton from '../common/SimpleButton'
import s from './ModalChannelContent.module.css'
import telegramWebAppAPI from "../../services/telegram";
import React from "react";

interface ModalChannelContentProps {
    closeModal: () => void;
}

const ModalChannelContent: React.FC<ModalChannelContentProps> = ({ closeModal }) => {
    return (
        <div className={s.container}>
            <div className={s.btnClose}>
                <SimpleButton imageUrl={`/images/modalTasksContent/btn_close.webp`} width={8.89} onClick={closeModal} />
            </div>
            <img className={s.caseImg} src={`/images/task_channel_modal.webp`} alt="case" />

            <h1 className={s.textTitle}>FOR PARTNERS</h1>

            <div className={s.textBox}>
                <p className={s.text}>Do you want to advertise your Telegram </p>
                <p className={s.text}>channel or mini app?</p>
                <p className={s.text}>FeeL FREE TO MESSAGE US</p>
            </div>

            <button className={s.btn} onClick={() => { telegramWebAppAPI.openTelegramLink("https://t.me/BaaTeam") }} type="button">
                <p className={s.text}>Message</p>
            </button>
        </div>
    )
}

export default ModalChannelContent