// src/redux/slices/boostSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Boost} from "../types";


interface BoostState {
    boosts: Boost[];
    claimBoostResult: number;
}

const initialState: BoostState = {
    boosts: [],
    claimBoostResult: -1,
};

const boostSlice = createSlice({
    name: 'boost',
    initialState,
    reducers: {
        setBoosts(state, action: PayloadAction<Boost[]>) {
            state.boosts = action.payload;
        },
        setClaimBoostResult(state, action: PayloadAction<number>) {
            state.claimBoostResult = action.payload;
        }
    },
});

export const { setClaimBoostResult, setBoosts } = boostSlice.actions;
export default boostSlice.reducer;
