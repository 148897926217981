import BoostListItem from './BoostListItem'
import s from './BoostList.module.css'
import {Boost, BoostStatus} from "../../redux/types";

interface BoostListProps {
    arrayItems: Boost[];
    status: Map<string, BoostStatus>;
    onBoostClick: (boost: Boost) => void;
}

const BoostList: React.FC<BoostListProps> = ({ arrayItems, status, onBoostClick }) => {
    // const pxToVw = (px: number) => +((px / window.innerWidth) * 100).toFixed(2);
    // const pxToVh = (px: number) => +((px / window.innerHeight) * 100).toFixed(2);
    // console.log('vw', pxToVw(19))
    // console.log('vh', pxToVh(16))
    return (
        <div className={s.list}>
            {arrayItems.map((item, index) => (
                <BoostListItem key={item.id} item={item} boostStatus={status.get(String(item.id))} index={index} arrLength={arrayItems.length} onBoostClick={onBoostClick} />
            ))}
        </div>
    )
}

export default BoostList