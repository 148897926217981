import { List, AutoSizer } from 'react-virtualized';
import LeadersListItem from './LeadersListItem';
import s from './LeadersList.module.css';
import { Leader, User } from "../../redux/types";
import { applyReward } from "./Rewards";

interface LeadersListProps {
    arrayItems: Leader[];
    currentUser: User,
}

const LeadersList: React.FC<LeadersListProps> = ({ arrayItems, currentUser }) => {
    let userInList = { id: currentUser.id, avatar: currentUser.avatar, username: currentUser.username, tokens: currentUser.tokens, reward: 0 };
    let pos = arrayItems.findIndex((el: Leader) => el.id === currentUser.id);

    let leaders = arrayItems.slice();

    // todo: need refactoring
    if (pos !== -1) {
        userInList = leaders[pos];
        if (userInList.tokens <= currentUser.tokens) {
            // sort updated leaderboard
            leaders.sort((l1, l2) => {
                const l1Tokens = l1.id === currentUser.id ? currentUser.tokens : l1.tokens;
                const l2Tokens = l2.id === currentUser.id ? currentUser.tokens : l2.tokens;

                return l1Tokens < l2Tokens ? 1 : -1
            });

            // recreate leaders
            let newLeaders: Leader[] = [];

            // update position rewards
            leaders.forEach((leader, idx) => {
                let reward = applyReward(idx + 1);
                newLeaders.push({ id: leader.id, avatar: leader.avatar, username: leader.username, tokens: (leader.id === currentUser.id ? currentUser.tokens : leader.tokens), reward: reward });
            });

            // find new position
            pos = newLeaders.findIndex((el: Leader) => el.id === currentUser.id);
            userInList = newLeaders[pos];
            leaders = newLeaders;
        }
    } else {
        if (leaders.length >= 500) {
            pos = currentUser.position !== -1 ? currentUser.position : -1;
        } else {
            let newLeaders: Leader[] = [];
            leaders.forEach((leader, idx) => {
                newLeaders.push({ id: leader.id, avatar: leader.avatar, username: leader.username, tokens: leader.tokens, reward: leader.reward });
            });
            newLeaders.push({ id: currentUser.id, avatar: currentUser.avatar, username: currentUser.username, tokens: currentUser.tokens, reward: applyReward(leaders.length + 1) });

            newLeaders.sort((l1, l2) => {
                const l1Tokens = l1.id === currentUser.id ? currentUser.tokens : l1.tokens;
                const l2Tokens = l2.id === currentUser.id ? currentUser.tokens : l2.tokens;

                return l1Tokens < l2Tokens ? 1 : -1
            });

            leaders = newLeaders;
            newLeaders = [];

            leaders.forEach((leader, idx) => {
                let reward = applyReward(idx + 1);
                newLeaders.push({ id: leader.id, avatar: leader.avatar, username: leader.username, tokens: (leader.id === currentUser.id ? currentUser.tokens : leader.tokens), reward: reward });
            });

            pos = newLeaders.findIndex((el: Leader) => el.id === currentUser.id);
            userInList = newLeaders[pos];
            leaders = newLeaders;
        }
    }

    const rowRenderer = ({ key, index, style }: { key: string; index: number; style: React.CSSProperties }) => {
        const item = leaders[index];
        return (
            <div key={key} style={style}>
                <LeadersListItem item={item} index={index} currentPlayer={false} arrLength={leaders.length} />
            </div>
        );
    };

    const rowHeight = ({ index }: { index: number }) => {
        const baseHeight = window.innerHeight > 730 ? window.innerHeight * 0.064 : window.innerHeight * 0.0727;
        // Если это последний элемент, удваиваем высоту 
        return index === leaders.length - 1 ? baseHeight * 1.65 : baseHeight;
    };
    return (
        <div className={s.list} >
            <AutoSizer>
                {({ height, width }) => {
                    return (

                        <List
                            width={width}
                            height={height || window.innerHeight}
                            rowCount={leaders.length}
                            rowHeight={rowHeight}
                            rowRenderer={rowRenderer}
                        />


                    );
                }}
            </AutoSizer>
            <div style={{ width: "91.11vw", aspectRatio: "360 / 58", height: "auto" }}></div>

            <div className={s.currentPlayerContainer}>
                <LeadersListItem key={-1} item={userInList} index={pos} currentPlayer={true} arrLength={leaders.length} />
            </div>
        </div>
    );
}

export default LeadersList;