// src/redux/sagas/tasksSaga.ts
import { takeEvery, takeLatest, call, put, all, ForkEffect, AllEffect } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {setCheckTaskResult, setTasks} from "../slices/tasksSlice";
import {apiCaller, waitForAuthorization} from "./apiCaller";
import {fetchUpdates} from "./gameSaga";
import {addCompletedTask} from "../slices/gameSlice";

function* handleFetchTasks(): Generator<any, void, any> {
    // Wait until the authorization is complete
    yield waitForAuthorization();

    try {
        const tasks = yield call(apiCaller, { apiFuncName: 'fetchTasks', args: {}});
        yield put(setTasks(tasks));
    } catch (error) {
        console.error(error);
    }
}

function* handleCheckAndMarkTask(action: PayloadAction<number>): Generator<any, void, any> {
    try {
        const {result} = yield call(apiCaller, { apiFuncName: 'checkAndMarkTask', args: action.payload});
        if(result) yield put(addCompletedTask(action.payload));
        yield put(setCheckTaskResult(result?1:0));

        yield call(fetchUpdates);
    } catch (error) {
        console.error(error);
    }
}

export default function* tasksSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([
        takeLatest('tasks/fetchTasks', handleFetchTasks),
        takeEvery('tasks/checkAndMarkTask', handleCheckAndMarkTask),
    ]);
}
