import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

import RiveWrapper from "./RiveWrapper";
import ProgressBarMain from '../../components/common/ProgressBarMain';
import s from './GamePage.module.css';


const GamePage: React.FC = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.game.user);

    const handleTap = () => {
        if (user.energy > 0) {
            dispatch({type: 'game/addTaps'}); // Adjust the coin increment logic as needed
        }
    };

    const completeWidth = (current: number, max: number) => current / max * 54
    const currentWidth = completeWidth(user.energy, user.max_energy);


    return (
        <div className={s.pageContainer}>
            <ProgressBarMain img={"energy"} completeWidth={currentWidth}
                barText={`${user.energy} / ${user.max_energy}`} />

            <div className={s.tapImage}>
                <RiveWrapper energy={user.energy} onClick={handleTap} />
            </div>
        </div>
    );
};



export default GamePage;
