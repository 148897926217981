import React, {useState, useEffect, useRef} from 'react';

interface CountDownTime {
    hours: string;
    minutes: string;
    seconds: string;
}

const CountdownTimer = ({seconds}: { seconds:number }) => {
    let secondsToReset = useRef(seconds);

    const calculateTimeLeft = (): CountDownTime => {
        const secondsLeft = secondsToReset.current;

        let timeLeft = {hours: '00', minutes: '00', seconds: '00'};

        if(seconds === 0) {
            return {hours: '00', minutes: '00', seconds: '00'};
        }

        if (secondsLeft > 0) {
            timeLeft.hours =  String(Math.floor((secondsLeft / (60 * 60)) % 24)).padStart(2, '0')
            timeLeft.minutes = String(Math.floor((secondsLeft / 60) % 60)).padStart(2, '0')
            timeLeft.seconds = String(Math.floor(secondsLeft % 60)).padStart(2, '0')
        }

        return timeLeft;
    }

const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

useEffect(() => {
    const timer = setTimeout(() => {
        secondsToReset.current -= 1;
        setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
});

return (
    <>
        {`${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`}
    </>
);
}

export default CountdownTimer;