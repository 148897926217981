import s from './NoConnection.module.css'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import Spinner from "../common/Spinner";

const NoConnection = () => {
    const dispatch = useDispatch();
    const runningRequest = useSelector((state: RootState) => state.game.runningRequest);

    return (
        <div className={s.container}>
            <div className={s.titleBox}>
                <img className={s.woodenPlate} src={`/images/header/wooden_plate.webp`} alt="wooden_plate" />
                <img className={s.paper} src={`/images/header/paper.webp`} alt="paper" />
                <p className={s.titleHeader}>ooops</p>
            </div>

            <div className={s.imageBox}>
                <img className={s.noConnectionBg} src={`/images/no_connection_bg.webp`} alt="no_connection_bg" />
                <img className={s.noConnectionWifi} src={`/images/no_connection_wifi.webp`} alt="no_connection_wifi" />
            </div>

            <div className={s.textBox}>
                <p className={s.textTitle}>no connection to internet</p>
                <p className={s.text}>please check your connection</p>
            </div>

            <button className={s.btnRetry} onClick={() => { dispatch({type: 'retryChannel'}); }} type="button">
                {runningRequest? <Spinner />: <p className={s.text}>Retry</p>}
            </button>
        </div>
    )
}

export default NoConnection