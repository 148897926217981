import { useState, useEffect, useRef } from "react";

const useModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isFadingOut, setIsFadingOut] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const openModal = () => {
        setIsOpen(true);
        setIsFadingOut(false);
    };

    const closeModal = () => {
        setIsFadingOut(true);
        timeoutRef.current = setTimeout(() => {
            setIsOpen(false);
            setIsFadingOut(false);
        }, 500); // Duration of the fadeOut animation
    };

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return { isOpen, isFadingOut, openModal, closeModal };
};

export default useModal