// src/redux/sagas/boostSaga.ts
import { takeEvery, call, put, all, ForkEffect, AllEffect } from 'redux-saga/effects';
import {setBoosts, setClaimBoostResult} from '../slices/boostSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import {apiCaller, waitForAuthorization} from "./apiCaller";
import {fetchUpdates} from "./gameSaga";

function* handleFetchBoosts(): Generator<any, void, any> {
    // Wait until the authorization is complete
    yield waitForAuthorization();

    try {
        const boosts = yield call(apiCaller, { apiFuncName: 'fetchBoosts', args: {}});
        yield put(setBoosts(boosts));
    } catch (error) {
        console.error(error);
    }
}

function* handleClaimBoost(action: PayloadAction<number>): Generator<any, void, any> {
    try {
        const {result} = yield call(apiCaller, { apiFuncName: 'checkAndMarkBoost', args: action.payload});
        yield put(setClaimBoostResult(result?1:0));

        yield call(fetchUpdates);
    } catch (error) {
        console.error(error);
    }
}

export default function* boostSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([
        takeEvery('boost/fetchBoosts', handleFetchBoosts),
        takeEvery('boost/claimBoost', handleClaimBoost),
    ]);
}
