// src/redux/types.ts
export interface User {
    id: number;
    username: string;
    language_code: string;
    avatar: string;
    tokens: number;  // Include score property
    energy: number;  // Include score property
    max_energy: number;  // Include score property
    boosts: { [key: number]: BoostStatus },
    tasks: { [key: number]: TaskStatus },
    position: number;
    reward: number;
    friends: { [key: number]: FriendStatus },
    is_active: boolean;
    completed_tasks: number[];
    claimed_boosts: number[];
    promo_attempts: number;
    promos_claimed: number[];
    ref_code: string;
}

export interface Leader {
    id: number;
    username: string;
    avatar: string;
    tokens: number;
    reward: number;
}

export interface BoostStatus {
    status: number;
    count: number;
    used_at: number;
    seconds_passed: number;
}

export interface TaskStatus {
    status: number;
    done_at: number;
}

export interface FriendStatus {
    active: boolean;
}

export interface Tokens {
    access_token: string;
    refresh_token: string;
    expires_in: number;
}

export interface Task {
    id: number;
    icon: string;
    title: string;
    url: string;
    reward: number;
}

export interface TaskStatus {
    status: number;
    done_at: number;
}


export interface Boost {
    id: number,
    icon: string,
    reward: number,
    title: string,
    description: string,
    type: number,
    max_count: number,
}


export enum BoostType {
    FRIENDS = 0,
    DAILY_ENERGY,
    AUTO_CLICKER
}

export interface Promo {
    id: number;
    uses: number;
    max_uses: number;
    reward: number;
    seconds_left: number;
}


declare module 'react-virtualized' {
    export class AutoSizer extends React.Component<any, any> { }
    export class List extends React.Component<any, any> { }
}

