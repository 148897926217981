// src/redux/slices/airdropSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Leader} from "../types";

interface LeaderboardState {
    leaderboard: Leader[];
}

const initialState: LeaderboardState = {
    leaderboard: [],
};

const leaderboardSlice = createSlice({
    name: 'leaderboard',
    initialState,
    reducers: {
        setLeaderboard(state, action: PayloadAction<Leader[]>) {
            state.leaderboard = action.payload;
        },
        // Additional reducers can be added here
    },
});

export const { setLeaderboard } = leaderboardSlice.actions;
export default leaderboardSlice.reducer;
