// src/redux/slices/tasksSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Task} from "../types";


interface TasksState {
    tasks: Task[];
    checkTaskResult: number,
}

const initialState: TasksState = {
    tasks: [],
    checkTaskResult: -1,
};


const tasksSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        setTasks(state, action: PayloadAction<Task[]>) {
            state.tasks = action.payload;
        },
        setCheckTaskResult(state, action: PayloadAction<number>) {
            state.checkTaskResult = action.payload;
        }
    },
});

export const { setTasks, setCheckTaskResult } = tasksSlice.actions;
export default tasksSlice.reducer;
