// src/redux/slices/promoSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Promo} from "../types";


interface BoostState {
    promo: Promo;
    loadedAt: number;
    claimPromoResult: number;
}

const initialState: BoostState = {
    promo: {id: 0, uses: 0, max_uses: 0, reward: 0, seconds_left: 0},
    loadedAt: 0,
    claimPromoResult: -1,
};

const promoSlice = createSlice({
    name: 'promo',
    initialState,
    reducers: {
        setPromo(state, action: PayloadAction<Promo>) {
            const localUses = state.promo.uses;
            state.promo = action.payload;
            if(localUses > state.promo.uses) state.promo.uses = localUses;
        },
        setLoadedAt(state, action: PayloadAction<number>) {
            state.loadedAt = action.payload;
        },
        setClaimPromoResult(state, action: PayloadAction<number>) {
            state.claimPromoResult = action.payload;
        }
    },
});

export const { setLoadedAt, setPromo, setClaimPromoResult } = promoSlice.actions;
export default promoSlice.reducer;
