// src/services/telegram.ts
import {User} from "../redux/types";

class TelegramWebAppAPI {
    init(onInitCallback: () => void) {
        let loaded = false;

        while(!loaded) {
            if (window.Telegram?.WebApp) {
                loaded = true;
                onInitCallback();
                window.Telegram.WebApp.disableVerticalSwipes();
            }
        }
    }

    async enableClosingConfirmation() {
        window.Telegram.WebApp.enableClosingConfirmation();
    }

    async disableClosingConfirmation() {
        window.Telegram.WebApp.disableClosingConfirmation();
    }

    async isClosingConfirmationEnabled() {
        return window.Telegram.WebApp.isClosingConfirmationEnabled;
    }

    getData(): string {
        return window.Telegram?.WebApp?.initData;
    }

    getUnsafeData(): any {
        return window.Telegram.WebApp.initDataUnsafe;
    }

    showBackButton() {
        window.Telegram?.WebApp?.BackButton?.show();
    }

    hideBackButton() {
        window.Telegram?.WebApp?.BackButton?.hide();
    }

    onBackClick(callback: () => void) {
        window.Telegram?.WebApp?.BackButton?.onClick(callback);
    }

    selectionChanged() {
        window.Telegram?.WebApp?.HapticFeedback?.selectionChanged();
    }

    expand() {
        if(window?.Telegram.WebApp) {
            window.Telegram.WebApp.expand();
        }
    }

    openTelegramLink(link: string) {
        window.Telegram.WebApp.openTelegramLink(link);
    }

    openLink(link: string) {
        window.Telegram.WebApp.openLink(link);
    }
}

export interface TelegramShareData {
    text: string;
    link: string;
    handleGenerateLink: () => void;
}

export const telegramShare = (user: User) : TelegramShareData => {
    const botUrl = process.env.REACT_APP_BOT_URL;
    const text = '👆 Get 350 $BAA by clicking on my referral link 🎁 \n\n🕹️ Play Game: Shear a Black Sheep, Refer Frens & Earn Coins! Top-500 leaders will share $30,000! 🏆🎉';
    const refID = user.ref_code? `c_${user.ref_code}`: `r_${user.id}`;
    const link = botUrl+`?start=${refID}`;

    const handleGenerateLink = () => {
        // Simulate generating a referral link
        telegramWebAppAPI.openTelegramLink('https://t.me/share/url?url=' + encodeURIComponent(link) + '&text=' + encodeURIComponent(text));
    };

    return {text: text, link: link, handleGenerateLink: handleGenerateLink}
}

const telegramWebAppAPI = new TelegramWebAppAPI();
export default telegramWebAppAPI;
