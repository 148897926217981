import SimpleButton from '../common/SimpleButton'
import s from './ModalPromoLimit.module.css'
import React from "react";

interface ModalPromoLimitProps {
    closeModal: () => void;
}

const ModalPromoLimit: React.FC<ModalPromoLimitProps> = ({ closeModal }) => {
    return (
        <div className={s.container}>
            <div className={s.btnClose}>
                <SimpleButton imageUrl={`/images/modalTasksContent/btn_close.webp`} width={8.89} onClick={closeModal}/>
            </div>
            <img className={s.caseImg} src={`/images/promos/broken_heart.webp`} alt="case"/>

            <h1 className={s.textTitle}>ENOUGH!</h1>

            <div className={s.textBox}>
                <p className={s.text}>You used all attempts. Try tomorrow </p>
                <img className={s.divider} src={`/images/modalTasksContent/divider.webp`} alt="devider"/>
            </div>
            <div className={s.textBox}>
                <p className={s.text}>Get the secret code from a friend,</p>
                <p className={s.text}>on the official $BAA pages</p>
                <p className={s.text}>or on partner channels</p>
            </div>

            <button className={s.btn} onClick={() => {
                closeModal()
            }} type="button">
                <p className={s.text}>OK</p>
            </button>
        </div>
    )
}

export default ModalPromoLimit