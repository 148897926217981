import React, { useState, useEffect } from 'react';
import s from './BoostList.module.css'

interface CountDownTime {
    hours: number;
    minutes: number;
}

const CountdownTimer = ({secondsPassed}: { secondsPassed:number }) => {

    const calculateTimeLeft = (): CountDownTime => {
        const difference = 24*60*60 - secondsPassed;
        let timeLeft = {hours: 0, minutes: 0};

        if(secondsPassed === 0) {
            return {hours: 24, minutes: 0};
        }

        if (difference > 0) {
            timeLeft.hours = Math.floor((difference / (60 * 60)) % 24)
            timeLeft.minutes = Math.floor((difference / 60) % 60)
            // seconds: Math.floor(difference % 60)
        }

        return timeLeft;
    }

const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

useEffect(() => {
    const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
    }, 30*1000);

    return () => clearTimeout(timer);
});

return (
    <div className={s.timer}>
        {timeLeft.hours > 0 || timeLeft.minutes > 0? `Available in: ${timeLeft.hours}h ${timeLeft.minutes}m`: 'Available Now'}
    </div>
);
}

export default CountdownTimer;