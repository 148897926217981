// src/pages/AirdropPage.tsx
import s from './LeaderboardPage.module.css';
import LeadersList from '../../components/LeadersList';
import PageContainer from "../../components/common/PageContainer";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const LeaderboardPage: React.FC = () => {
    const { leaderboard } = useSelector((state: RootState) => state.leaderboard);
    const { user } = useSelector((state: RootState) => state.game);
    return (
        <PageContainer>
            <img className={s.confettiImg} src={'/images/leaders/confetti.webp'} alt="confetti" />
            <div className={s.headerContainer}>

                <img className={s.oliveImg} src={'/images/leaders/olive.webp'} alt="olive" />
                <img className={s.crownImg} src={'/images/leaders/crown.webp'} alt="crown" />
                <p className={s.title}>top 500</p>
                <p className={s.titleLower}>leaderboard</p>
                <div className={s.titleOnBaseContainer}>
                    <div className={s.titleOnBase}>
                        <p className={s.text}>rank</p>
                    </div>
                    <div className={s.titleOnBase}>
                        <p className={s.text}>prizes</p>
                    </div>
                </div>
            </div>
            <LeadersList arrayItems={leaderboard} currentUser={user} />
        </PageContainer>
    );
};

export default LeaderboardPage;
