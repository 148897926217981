// src/App.tsx
import React, { useEffect } from "react";
import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
} from "react-router-dom";
import BottomNavigation from "./components/BottomNavigation";
import styles from "./App.module.css";
import telegramWebAppAPI from "./services/telegram";
import Header from "./components/Header";
import "./assets/fonts/fonts.css";
import NavigationHandler from "./components/NavigationHandler";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/store";
import CheatList from "./components/CheatList";
import LoadScreen from "./components/LoadScreen";
import GamePage from "./pages/GamePage/GamePage";
import Modal from "./components/common/Modal";
import { setCurrentModal, showToast } from "./redux/slices/gameSlice";
import s from "./pages/GamePage/GamePage.module.css";
import FriendsPage from "./pages/FriendsPage";
import BoostPage from "./pages/BoostPage";
import TasksPage from "./pages/TasksPage";
import LeaderboardPage from "./pages/LeaderboardPage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NoConnection from "./components/NoConnection";

const MainWrapper: React.FC = () => {
    const isLoaded = useSelector((state: RootState) => state.game.isLoaded)

    return (
        <>
            {(!isLoaded) && <LoadWrapper />}
            {(isLoaded) && <CheatWrapper />}
        </>
    );
};

const LoadWrapper: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        telegramWebAppAPI.init(async () => {
            telegramWebAppAPI.expand();

            let data = telegramWebAppAPI.getData();

            dispatch({ type: 'game/authorize', payload: { data: data } });
            dispatch({ type: 'game/preload' });
            dispatch({ type: 'promo/everyMinute' });
            dispatch({ type: 'game/every5Minutes' });
        });
    }, [dispatch]);

    return (
        <LoadScreen />
    );
};

const CheatWrapper: React.FC = () => {
    const userTasks = useSelector((state: RootState) => state.game.user.tasks);
    const { tasks } = useSelector((state: RootState) => state.tasks);
    const hasCheated = tasks.length > 0 && userTasks != null && Array.from(Object.values(userTasks)).some(taskStatus => taskStatus.status === 0);

    return (
        <>
            {hasCheated && <CheatList />}
            {!hasCheated && <AppContent />}
        </>
    );
};

const PageModal: React.FC = () => {
    const dispatch = useDispatch();
    const currentModal = useSelector((state: RootState) => state.game.currentModal)
    const toastMessage = useSelector((state: RootState) => state.game.toast);

    useEffect(() => {
        if (toastMessage === '') return;

        toast.success(toastMessage, {
            autoClose: 1000,
            className: 'custom-toast-success',
            progressClassName: 'custom-progress'
        });

        dispatch(showToast(""));
    }, [dispatch, toastMessage]);

    return (
        <>
            {currentModal !== '' && <Modal closeModal={() => dispatch(setCurrentModal(""))}>
                <img
                    src={"/images/bg_secondary.webp"}
                    alt="background"
                    className={s.BackgroundImage}
                />
                <div className={s.ContentWrapper}>
                    {currentModal === 'frens' && <FriendsPage />}
                    {currentModal === 'boost' && <BoostPage />}
                    {currentModal === 'tasks' && <TasksPage />}
                    {currentModal === 'leaders' && <LeaderboardPage />}
                </div>
            </Modal>}
        </>
    );
}

const AppContent: React.FC = () => {
    const isNetworkError = useSelector((state: RootState) => state.game.isNetworkError)

    return (
        <div className={`${styles.App}`}>
            <Header />
            <NavigationHandler />
            <div className={styles.App}>
                <div className={styles.content}>
                    <PageModal />
                    <Outlet />
                </div>
            </div>
            <BottomNavigation />
            <div className={styles.listHeight}></div>

            <div style={{ opacity: isNetworkError ? 1 : 0, visibility: isNetworkError ? 'visible' : 'hidden' }}>
                <Modal blocking={true}>
                    <NoConnection />
                </Modal>
            </div>

            <ToastContainer />
        </div>
    );
};

const router = createBrowserRouter([
    {
        path: "/",
        Component: MainWrapper,
        children: [
            {
                index: true,
                Component: GamePage,
                loader() {
                    return { path: '/' }
                }
            },
        ]
    },
]);

const App: React.FC = () => {
    return (
        <RouterProvider router={router} />
    );
};

export default App;
